interface ModalClasses {
  container?: Record<string, boolean>
  backdrop?: Record<string, boolean>
  modal?: Record<string, boolean>
  close?: Record<string, boolean>
}

const defaultClasses: ModalClasses = {
  container: {
    'fixed inset-0 z-50 flex flex-col items-end justify-end sm:items-center sm:justify-center sm:p-10':
      true,
  },
  backdrop: {
    'fixed inset-0 z-10 bg-black/50 backdrop-blur-[2px] transition-opacity duration-300':
      true,
  },
  modal: {
    'z-20 h-auto w-full max-w-4xl': true,
    'max-h-[90dvh] overflow-hidden': true,
    'rounded-t-lg sm:rounded-b-lg': true,
    'bg-light dark:bg-neutral-dark-2 text-dark dark:text-light': true,
    'duration-200 drop-shadow-2xl': true,
    '~px-5/8 py-4': true,
  },
  close: {
    'absolute right-2 top-2 z-20 rounded-full': true,
    'text-dark bg-light dark:text-light dark:bg-neutral-dark-2': true,
    'hover:bg-neutral-dark-1 transition-colors duration-200': true,
  },
}

export default function useModal({
  onClose,
  initiallyOpened = false,
  classes = {},
}: {
  onClose: () => void
  initiallyOpened?: boolean
  classes?: {
    container?: string
    backdrop?: string
    modal?: string
    close?: string
  }
}) {
  const isOpen = ref(initiallyOpened)

  function close() {
    isOpen.value = false
    setTimeout(onClose, 200)
  }

  function open() {
    setTimeout(() => {
      isOpen.value = true
    }, 200)
  }

  const cssClasses = computed(() => ({
    container: {
      ...defaultClasses.container,
      [classes.container || '']: true,
    },
    backdrop: {
      ...defaultClasses.backdrop,
      [classes.backdrop || '']: true,
      'pointer-events-auto opacity-100': isOpen.value,
      'pointer-events-none opacity-0': !isOpen.value,
    },
    modal: {
      ...defaultClasses.modal,
      [classes.modal || '']: true,
      'translate-y-0 opacity-100 delay-200 sm:scale-100': isOpen.value,
      'translate-y-full opacity-0 sm:scale-75': !isOpen.value,
    },
    close: {
      ...defaultClasses.close,
      [classes.close || '']: true,
    },
  }))

  return {
    isOpen,
    close,
    open,
    cssClasses,
  }
}
