import validate from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import design_45global from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/layers/theme/middleware/design.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/layers/base/middleware/affiliate-and-referrer.global.ts";
import backend_45token_45global from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/layers/base/middleware/backend-token.global.ts";
import consent_45global from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/layers/base/middleware/consent.global.ts";
import redirects_45global from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/apps/kinoheld/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/kinoheld/live/2024-09-16_11-45-45_0ad26e0/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  design_45global,
  affiliate_45and_45referrer_45global,
  backend_45token_45global,
  consent_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}